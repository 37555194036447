var headroom;

// Load background images
jQuery(".load-bg-img").each(function (i, obj) {
  loadBgImage(jQuery(this));
});

function loadBgImage(element) {
  var large_img = element.attr("data-bg-large");
  var small_img = element.attr("data-bg-small");

  var bgImg = new Image();
  bgImg.onload = function () {
    element.css("background-image", "url(" + bgImg.src + ")");
    element.addClass("loaded");
  };

  if (jQuery(document).width() > 800) {
    bgImg.src = element.attr("data-bg");
  } else if (jQuery(document).width() > 400) {
    bgImg.src = element.attr("data-bg-small");
  } else {
    bgImg.src = element.attr("data-bg-tiny");
  }
}




jQuery(document).ready(function () {
  jQuery(document).on("click", ".dropdown-menu", function (e) {
    e.stopPropagation();
  });
});

current_employee = "";

jQuery(document).ready(function () {
  jQuery(".answer.collapse").on("hide.bs.collapse", function () {
    jQuery(this).parent().find("h3").removeClass("showing");
  });

  jQuery(".answer.collapse").on("show.bs.collapse	", function () {
    jQuery(this).parent().find("h3").addClass("showing");
  });

  jQuery(".nav.collapse").on("hide.bs.collapse", function () {
    jQuery(".navbar-container").removeClass("show");
    jQuery(".navbar-toggler").removeClass("show");
    jQuery("html").removeClass("show-nav");
    jQuery("body").removeClass("no-scroll");
    setTimeout(function () {
      jQuery(".navbar-container").removeClass("showing");
      jQuery(".navbar-toggler").removeClass("showing");
      jQuery("html").removeClass("showing-nav");
    }, 100);
  });

  jQuery(".nav.collapse").on("show.bs.collapse	", function () {
    jQuery(".navbar-container").addClass("showing");
    jQuery(".navbar-toggler").addClass("showing");
    jQuery("html").addClass("showing-nav");
    jQuery("body").addClass("no-scroll");

    setTimeout(function () {
      jQuery(".navbar-container").addClass("show");
      jQuery(".navbar-toggler").addClass("show");
      jQuery("html").addClass("show-nav");
    }, 100);
  });

  jQuery(".the-search-form.collapse").on("show.bs.collapse	", function () {
    setTimeout(function () {
      jQuery(".the-search-form input.search-field").focus();
    }, 300);
  });
});


/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    common: {
      init: function () {
        jQuery(
          ".marry-widows h1, .marry-widows h2, .marry-widows h3, .marry-widows p"
        ).each(function () {
          var string = jQuery(this).html();
          string = string.replace(/ ([^ ]*)$/, "&nbsp;$1");
          jQuery(this).html(string);
        });

        var viewport_width = jQuery(document).width();

        var offset = 0;
        if (vars.show_top_navigation) {
          offset = 40;
        }
        if (viewport_width < 992) {
          // Check to see if we're showing an announcement.
          if (vars.show_top_navigation && jQuery('html').hasClass('show-announcement')) {
            offset = 40;
          }
        }
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      },
    },
    // Home page
    home: {
      init: function () {
        // JavaScript to be fired on the home page
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      },
    },
    // About us page, note the change from about-us to about_us.
    about_us: {
      init: function () {
        // JavaScript to be fired on the about us page
      },
    },
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = funcname === undefined ? "init" : funcname;
      fire = func !== "";
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === "function";

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire("common");

      // Fire page-specific init JS, and then finalize JS
      $.each(
        document.body.className.replace(/-/g, "_").split(/\s+/),
        function (i, classnm) {
          UTIL.fire(classnm);
          UTIL.fire(classnm, "finalize");
        }
      );

      // Fire common finalize JS
      UTIL.fire("common", "finalize");
    },
  };

  // Load Events
  jQuery(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.

function getScrollTop() {
  if (typeof pageYOffset !== "undefined") {
    //most browsers except IE before #9
    return pageYOffset;
  } else {
    var B = document.body; //IE 'quirks'
    var D = document.documentElement; //IE with doctype
    D = D.clientHeight ? D : B;
    return D.scrollTop;
  }
}

function getHeaderTop(div) {
  var winTop;
  if (!div) {
    winTop = jQuery(window).scrollTop();
  } else {
    winTop = jQuery(div).offset().top;
  }
  var headerTop = winTop;
  if (headerTop > headerHidden) {
    headerTop = headerHidden;
  } else if (headerTop < 0) {
    headerTop = 0;
  }
  return headerTop;
}


jQuery(document).ready(function () {
  jQuery("html").addClass("js");
});



/* ================================
 * Map locations
 * ============================= */
jQuery(document).ready(function () {


  jQuery(document).on("facetwp-loaded", function () {

    var in_use = false;

    // see if any facets are in use
    (jQuery).each(FWP.facets, function (name, val) {
      if (val.length > 0) {
        in_use = true;
      }
    });


    if (!in_use) {
      jQuery(".reset-button").addClass("disabled");
      jQuery(".reset-button").attr("disabled", "disabled");
    } else {
      jQuery(".reset-button").removeClass("disabled");
      jQuery(".reset-button").removeAttr("disabled", "disabled");
    }


    jQuery(".facetwp-template .load-bg-img").each(function (i, obj) {
      loadBgImage(jQuery(this));
    });

    jQuery(".facetwp-template").removeClass("loading");

  });



});

jQuery(document).ready(function () {
  jQuery(document).on("facetwp-refresh", function () {
    jQuery(".facetwp-template").addClass("loading");
  });
});

// Accordian stuff
jQuery(document).ready(function () {
  // the current open accordion will not be able to close itself
  jQuery('.accordion button[data-toggle="collapse"]').on("click", function (e) {
    if (!jQuery(this).hasClass("collapsed")) {
      e.stopPropagation();
    }
  });
});

function createCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

function eraseCookie(name) {
  createCookie(name, "", -1);
}

/* ================================
 * Cookie notice
 * ============================= */
jQuery(document).ready(function () {
  var allow_cookies = readCookie("allow_cookies");
  if (allow_cookies !== "yes") {
    jQuery(".cookie-notice").addClass("display");
    jQuery(".cookie-notice").addClass("inplace");
  } else {
    console.log("Cookies allowed.");
  }

  jQuery("#allow-cookies").click(function (event) {
    createCookie("allow_cookies", "yes", 7);

    setTimeout(function () {
      jQuery(".cookie-notice").removeClass("display");
    }, 200);
    jQuery(".cookie-notice").removeClass("inplace");

    return false;
  });
});






jQuery(document).ready(function () {


    jQuery('.skin-color-toggle a').on('click', function () { 
        var skin_color = jQuery(this).attr('data-skin');

        // Remove checked from everything else
        jQuery('.skin-color-toggle li').removeClass('checked');

        // Add checked to this item.
        jQuery(this).parent().addClass('checked');

        // If we are switching to light
        if (skin_color == 'light') {
            jQuery('.sc-dark').css('display', 'none');
            jQuery('.sc-light').css('display', 'block');
        }
        // Switching to dark
        else {
            jQuery('.sc-light.sc-has-dark').css('display', 'none');
            jQuery('.sc-dark').css('display', 'block');
        }

        return false;
    });

    jQuery("body").on("contextmenu", "img", function(e) {
      return false;
    });
});
  

// Open the login form.
jQuery(document).ready(function () {
    jQuery('a.login-popup').on('click', function() {
        jQuery(".modal.register-login").modal();
        return false;
    });
    
});











// Search autocomplete.
jQuery(document).ready(function () {
  var substringMatcher = function (strs) {
    return function findMatches(q, cb) {
      var matches, substringRegex;

      // an array that will be populated with substring matches
      matches = [];

      // regex used to determine if a string contains the substring `q`
      substrRegex = new RegExp(q, 'i');

      // iterate through the pool of strings and for any string that
      // contains the substring `q`, add it to the `matches` array
      jQuery.each(strs, function (i, str) {
        if (substrRegex.test(str)) {
          matches.push(str);
        }
      });

      cb(matches);
    };
  };


  jQuery('.ac-search.typeahead').typeahead({
    hint: true,
    highlight: true,
    minLength: 1
  },
    {
      name: 'ac_values',
      source: substringMatcher(ac_values),
      limit: 10
    })
    .bind('typeahead:select', function (ev, selection) {
      jQuery(this).val(selection);
      jQuery(this).parents('form').submit();
    });
  
  
});



function vdx_load_api_image(id) {
  jQuery.ajax({
    type: "post",
    dataType: "json",
    url: vars.ajaxurl,
    data: {
      action: "vdx_load_image",
      id: id,
    },
    success: function(response) {
      //console.log(response);
      if (response.src != '') {
        jQuery('#img-' + id + ' img').attr('src', response.src);
      }
      else {
        console.log('Failed to load image');
        console.log(response);
      }
    },
    error: function(errorThrown) {
      console.log("ERROR: " + errorThrown);
    }
  });
}


// Load API images.
jQuery(document).ready(function () {
  jQuery(".grid-item:not(.hidden-result) .api-image-load").each(function (i, obj) {
    var id = jQuery(this).attr('data-image-id');
    vdx_load_api_image(id);
    // Remove the class on this item.
    jQuery(this).removeClass('api-image-load');
  });


  // Check if we have any more .hidden result classes
  if (jQuery('.hidden-result').length <= 0) {
    // Hide the load more section.
    jQuery('.load-more').css('display', 'none');
  }


  // Load more button.
  jQuery('#load-more-images').on('click', function() {

    var i = 0;
    jQuery(".grid-item.hidden-result .api-image-load").each(function (i, obj) {
      i++;
      // Let's only load like 30 at a time.
      if (i >= 25) {
        return false;
      }

      var id = jQuery(this).attr('data-image-id');
      vdx_load_api_image(id);
      // Remove the class on this item.
      jQuery(this).removeClass('api-image-load');
      // Also remove the hidden class.
      jQuery(this).parents('.hidden-result').removeClass('hidden-result');
    });

    // Check if we have any more .hidden result classes
    if (jQuery('.hidden-result').length <= 0) {
      // Hide the load more section.
      jQuery('.load-more').css('display', 'none');
    }


  });
});

// Checkbox stuff
jQuery(document).ready(function () {
  
  jQuery('.api-results .grid-item .inner').on('click', function() {
    var checkbox = jQuery(this).find('input');
    if (checkbox.prop("checked")) {
      // uncheck it.
      checkbox.prop('checked', false);
      jQuery(this).removeClass('selected');
    }
    else {
      // check it.
      checkbox.prop('checked', true);
      jQuery(this).addClass('selected');
    }

    update_img_count();
  });
  
});


var image_download_ct = 0;
// Inialize our events.
jQuery(document).ready(function () {
  // On form submit
  jQuery("#img_form_submit").on("click", function (event) {

    jQuery('#img-result').html('');
    jQuery('#preparing-modal .loader').css('display', 'block');



    // Get images array.
    var images = [];
    images = jQuery('#img_form input:checked').map(function() {
      return jQuery(this).val();
    });
    var image_arr = images.get();

    
    jQuery.ajax({
      type: "post",
      dataType: "json",
      url: vars.ajaxurl,
      data: {
        action: "vdx_images_log_search",
        images: image_arr,
      },
      success: function (response) {

        console.log('Image search result.' + response.search_result);
        // If the result array has any values we have duplicates.
        var search_result = response.search_result;
        var duplicates_list = '';

        if (search_result.length > 0) {
          for (var i = 0; i < search_result.length; i++) {
            if (duplicates_list != '') {
              duplicates_list += ', ';
            }
            duplicates_list += search_result[i];
          }
        }

        jQuery('#preparing-modal .loader').css('display', 'none');

        var confirm_message = '<h3>Please confirm</h3>';
        confirm_message += '<p>You are about to download <span>'+image_download_ct+'</span> image(s).</p>';
        if (duplicates_list != '') {
          confirm_message += '<p>You are about to download images that have been downloaded previously. This is appropriate for new use of the image.</p>';
          confirm_message += '<p>Image IDs that have been previously downloaded: ' + duplicates_list + '</p>';
        }
        confirm_message += '<button id="img_form_submit_confirm" type="button" class="butt">Download <span>'+image_download_ct+'</span> Image(s)</button>';

        jQuery('#img-result').html(confirm_message);

        jQuery("#img_form_submit_confirm").on("click", function (event) {

          // Make sure loader is showing and clear any messages out.
          jQuery('#preparing-modal .loader').css('display', 'block');
          jQuery('#img-result').html('');
      
          vdx_submit_downloads_form();
      
          // stop the form from submitting the normal way and refreshing the page
          //event.preventDefault();
        });  
        
      },
      error: function (errorThrown) {
        console.log("ERROR: There was an issue searching the logs");
      },
    });

  });


  // Update image count when user selects something.
  jQuery('.image-selectors').on('change', function() {
    // Find total number of checkboxes checked.
    update_img_count();
  });

});

function update_img_count() {
  image_download_ct = 0;
  jQuery(".image-selectors").each(function () {
    if (this.checked) {
      image_download_ct++;
    }
  });

  
  var submit_button_value = '';

  if (image_download_ct == 1) {
    submit_button_value = 'Download <span>'+image_download_ct+'</span> Image';
  }
  else {
    submit_button_value = 'Download <span>'+image_download_ct+'</span> Images';
  }

  // Disable / enable button.
  if (image_download_ct <= 0) {
    jQuery('#img_form_submit').prop("disabled",true).addClass('disabled');
  }
  else {
    jQuery('#img_form_submit').prop("disabled",false).removeClass('disabled');
  }

  jQuery('#img_form_submit').html(submit_button_value);
}

function vdx_submit_downloads_form() {

  // Get images array.
  var images = [];
  images = jQuery('#img_form input:checked').map(function() {
    return jQuery(this).val();
  });
  var image_arr = images.get();

  // Get image data arrays
  var captions = {};
  var diagnoses = {};
  var skin_types = {};
  var body_parts = {};
  //captions = jQuery('#img_form input[name=captions]').val();
  for (var i = 0; i < image_arr.length; i++) {
    if (jQuery('#caption_'+image_arr[i]).val() != '') {
      captions[image_arr[i]] = jQuery('#caption_'+image_arr[i]).val();
    }
    if (jQuery('#diagnosis_'+image_arr[i]).val() != '') {
      diagnoses[image_arr[i]] = jQuery('#diagnosis_'+image_arr[i]).val();
    }
    if (jQuery('#skin_type_'+image_arr[i]).val() != '') {
      skin_types[image_arr[i]] = jQuery('#skin_type_'+image_arr[i]).val();
    }
    if (jQuery('#body_parts_'+image_arr[i]).val() != '') {
      body_parts[image_arr[i]] = jQuery('#body_parts_'+image_arr[i]).val();
    }
  }


  jQuery.ajax({
    type: "post",
    dataType: "json",
    url: vars.ajaxurl,
    data: {
      action: "vdx_images_download",
      images: image_arr,
      captions: JSON.stringify(captions),
      diagnoses: JSON.stringify(diagnoses),
      skin_types: JSON.stringify(skin_types),
      body_parts: JSON.stringify(body_parts),
    },
    success: function (response) {
      console.log(response);

      // Hide loader.
      jQuery('#preparing-modal .loader').css('display', 'none');

      if (response.zip_url != '') {
        var result_html = '';
        result_html += '<p>Your image files are ready for download.</p>';
        result_html += '<a href="' + response.zip_url + '" class="butt">Download Zip</a>';
        jQuery("#img-result").html(result_html);

        // Clear all the checkboxes
        jQuery('#img_form .inner').removeClass('selected');
        jQuery('#img_form input:checked').prop('checked', false);
      }
      
      //jQuery("#rep-form-submit").removeAttr("disabled", "disabled");
    },
    error: function (errorThrown) {

      // Hide loader.
      jQuery('#preparing-modal .loader').css('display', 'none');

      jQuery("#img-result").html(
        "Error! There was an issue preparing your download."
      );
      console.log("ERROR: " + errorThrown);
    },
  });

}